import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  LuSearch,
  LuMapPin,
  FaMoneyBill,
  LuRuler,
  MdCategory,
  MdOutlineSelectAll,
} from "../../../public/assets/icons/vander";
import SearchHelper from "@/utils/SearchHelper";
import { useTranslations } from "@/TranslationContext";

const SearchForm = ({
  container,
  queryParams,
}: {
  container: "hero" | "sidebar";
  queryParams: any;
}) => {
  const itemsToShow = {
    hero: ["keyword", "location", "minPrice", "heroSubmit"],
    sidebar: [
      "filterLabel",
      "keyword",
      "location",
      "minPrice",
      "minPrice",
      "maxPrice",
      "minSize",
      "maxSize",
      "sidebarSubmit",
    ],
  };

  const heroInputClasses = "filter-border ";
  const sideBarInputClasses = "";
  let inputClasses =
    container == "hero" ? heroInputClasses : sideBarInputClasses;

  const [flatLocations, setFlatLocations] = useState([]);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const [location, setLocation] = useState(null);
  const [query, setQuery] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minSize, setMinSize] = useState("");
  const [maxSize, setMaxSize] = useState("");
  const [orderBy, serOrderBy] = useState("relevance");
  const [listingType, setListingType] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [placeholder, setPlaceholder] = useState("eg. barabarani");
  const [typingIndex, setTypingIndex] = useState(0);
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);

  const t = useTranslations();

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const listing_types = [
    {
      label: t.all,
      value: "",
    },
    {
      label: t.buy,
      value: "sale",
    },
    {
      label: t.rent,
      value: "rent",
    },
  ] as any[];

  const property_types = [
    {
      label: t.all,
      value: "",
    },
    {
      label: t.residentialPlots,
      value: "residential_plot",
      for: ["sale"],
    },
    {
      label: t.house,
      value: "house",
      for: ["sale", "rent"],
    },
    {
      label: t.farms,
      value: "farm",
      for: ["sale"],
    },
    {
      label: t.commercialPlots,
      value: "industrial_plot",
      for: ["sale"],
    },

    {
      label: t.retailSpace,
      value: "retail_space",
      for: ["rent"],
    },
    {
      label: t.officeSpace,
      value: "office_space",
      for: ["rent"],
    },
    {
      label: t.warehouse,
      value: "warehouse",
      for: ["rent"],
    },
  ] as any[];

  const placeholders = [
    "eg. barabarani",
    "eg. furnished",
    "eg. master bedroom",
    "eg. 2 bedrooms",
    "eg. mkopo",
    "eg. vyumba viwili",
    "eg. apartment",
    "eg. studio apartment",
  ];

  useEffect(() => {
    const currentPlaceholder = placeholders[currentPlaceholderIndex];
    if (typingIndex < currentPlaceholder.length) {
      const timeoutId = setTimeout(() => {
        setPlaceholder(currentPlaceholder.slice(0, typingIndex + 1));
        setTypingIndex(typingIndex + 1);
      }, 20); // Adjust typing speed here

      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setTypingIndex(0);
        setCurrentPlaceholderIndex(
          (currentPlaceholderIndex + 1) % placeholders.length
        );
      }, 2000); // Time before switching to next placeholder

      return () => clearTimeout(timeoutId);
    }
  }, [typingIndex, currentPlaceholderIndex]);

  useEffect(() => {
    (async () => {
      setIsLoadingLocation(true);
      let locations = (await SearchHelper.getAllRegions())?.map((location) => {
        return {
          value: location.uuid,
          label: location.full_name,
        };
      });
      setFlatLocations(locations);
      setIsLoadingLocation(false);

      await setSelectedLocation(locations);
      setQuery(queryParams?.query);

      setMinPrice(queryParams?.minPrice);
      setMaxPrice(queryParams?.maxPrice);
      setMinSize(queryParams?.minSize);
      setMaxSize(queryParams?.maxSize);
      serOrderBy(queryParams?.orderBy);
      setPropertyType(queryParams?.property_type);
      setListingType(queryParams?.listing_type);
    })();
  }, []);

  async function setSelectedLocation(locationsList: any[]) {
    let queryLocation = queryParams?.location;
    let currentLocation = locationsList.find(
      (location) => location.value == queryLocation
    );

    if (!currentLocation) {
      if (queryLocation) {
        currentLocation = await SearchHelper.getLocationByUuid(queryLocation);
        if (currentLocation) {
          let _currentLocation = {
            value: currentLocation.uuid,
            label: currentLocation.full_name,
          };
          locationsList.push(_currentLocation);
          setFlatLocations(locationsList);

          setLocation(_currentLocation);
        }
      }
    } else {
      setLocation(currentLocation);
    }
  }

  async function onLocationKeyDown(event: any): Promise<void> {
    let value = event.target.value;
    if (value.length < 1) {
      return;
    }
    setIsLoadingLocation(true);

    if (timerId) {
      clearTimeout(timerId);
    }

    let _timerId = setTimeout(async () => {
      setIsLoadingLocation(true);
      let locations = (
        await SearchHelper.getLocationByKeyword(event.target.value)
      )?.map((location) => {
        return {
          value: location.uuid,
          label: location.full_name,
        };
      });

      setIsLoadingLocation(false);
      setFlatLocations(locations);
    }, 1000);

    setTimerId(_timerId);
  }

  function isInItemsToShow(item: string): boolean {
    return itemsToShow[container].includes(item);
  }

  return (
    <form action="listings">
      <input type="hidden" name="page" value={1} />
      <input type="hidden" name="orderBy" value={orderBy} />
      <div className="registration-form  text-start">
        <div
          className={
            container == "hero"
              ? "md:flex space-y-5 md:space-y-0"
              : " space-y-5"
          }
        >
          <div className="flex-1 ">
            <label className="form-label text-slate-900  font-medium">
              Keyword
            </label>
            <div className={"filter-search-form relative mt-2 " + inputClasses}>
              <LuSearch className="icons" />
              <input
                name="query"
                type="text"
                className="form-input filter-input-box bg-gray-50  border-0"
                placeholder={placeholder}
                value={query || ""}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex-1">
            <label className="form-label text-slate-900  font-medium">
              {t.propertyType}
            </label>
            <div className="filter-search-form relative  mt-2">
              <MdCategory className="icons" />
              <select
                className="form-input filter-input-box bg-gray-50  border-0"
                name="property_type"
                value={propertyType || ""}
                onChange={(v: any) => {
                  setPropertyType(v.value);
                }}
              >
                {property_types.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex-1">
            <label className="form-label text-slate-900  font-medium">
              {t.offerType}
            </label>
            <div className="filter-search-form relative   mt-2">
              <MdOutlineSelectAll className="icons" />
              <select
                className="form-input filter-input-box bg-gray-50  border-0"
                name="listing_type"
                value={listingType || ""}
                onChange={(v: any) => {
                  setListingType(v.value);
                }}
              >
                {listing_types.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {isClient && (
            <div className="flex-1">
              <label
                htmlFor="buy-properties"
                className="form-label text-slate-900  font-medium"
              >
                {t.location}
              </label>
              <div
                className={"filter-search-form relative mt-2 " + inputClasses}
              >
                <LuMapPin className=" icons" />
                <Select
                  id="location-select"
                  className="form-input filter-input-box bg-gray-50  border-0"
                  options={flatLocations}
                  name="location"
                  isClearable={true}
                  placeholder="eg. Kimara"
                  isLoading={isLoadingLocation}
                  onKeyDown={onLocationKeyDown}
                  noOptionsMessage={() => {
                    return isLoadingLocation ? "Searching" : "Not found";
                  }}
                  value={location || null}
                  onChange={(v: any) => {
                    setLocation(v);
                  }}
                />
              </div>
            </div>
          )}
          <div className="flex-1">
            <label className="form-label text-slate-900  font-medium">
              {t.minPrice} (TZS)
            </label>
            <div className={"filter-search-form relative mt-2 "}>
              <FaMoneyBill className="icons" />
              <input
                name="minPrice"
                type="number"
                className="form-input filter-input-box bg-gray-50  border-0"
                placeholder="eg. 5000000"
                value={minPrice || ""}
                onChange={(event) => {
                  setMinPrice(event.target.value);
                }}
              />
            </div>
          </div>

          {isInItemsToShow("maxPrice") && (
            <div className="flex-1">
              <label
                htmlFor="buy-min-price"
                className="form-label text-slate-900  font-medium"
              >
                {t.maxPrice} (TZS)
              </label>
              <div className="filter-search-form relative   mt-2">
                <FaMoneyBill className="icons" />
                <input
                  name="maxPrice"
                  type="number"
                  className="form-input filter-input-box bg-gray-50  border-0"
                  placeholder="eg. 10000000"
                  value={maxPrice || ""}
                  onChange={(event) => {
                    setMaxPrice(event.target.value);
                  }}
                />
              </div>
            </div>
          )}

          {isInItemsToShow("minSize") && (
            <div className="flex-1">
              <label
                htmlFor="buy-min-price"
                className="form-label text-slate-900  font-medium"
              >
                {t.minSize} (SQM)
              </label>
              <div className="filter-search-form relative   mt-2">
                <LuRuler className="icons" />
                <input
                  name="minSize"
                  type="number"
                  className="form-input filter-input-box bg-gray-50  border-0"
                  placeholder="eg. 400"
                  value={minSize || ""}
                  onChange={(event) => {
                    setMinSize(event.target.value);
                  }}
                />
              </div>
            </div>
          )}

          {isInItemsToShow("minSize") && (
            <div className="flex-1">
              <label
                htmlFor="buy-min-price"
                className="form-label text-slate-900  font-medium"
              >
                {t.maxSize} (SQM)
              </label>
              <div className="filter-search-form relative   mt-2">
                <LuRuler className="icons" />
                <input
                  name="maxSize"
                  type="number"
                  className="form-input filter-input-box bg-gray-50  border-0"
                  placeholder="eg. 1200"
                  value={maxSize || ""}
                  onChange={(event) => {
                    setMaxSize(event.target.value);
                  }}
                />
              </div>
            </div>
          )}

          {isInItemsToShow("heroSubmit") && (
            <div className="md:ml-3 md:flex mt-8 md:mt-0 items-end">
              <div>
                <button
                  type="submit"
                  name="search"
                  className="btn cursor-pointer w-full md:w-auto h-[58px] bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white searchbtn submit-btn  rounded"
                >
                  {t.search}
                </button>
              </div>
            </div>
          )}

          {isInItemsToShow("sidebarSubmit") && (
            <div className="mt-5">
              <button
                type="submit"
                name="search"
                className="btn cursor-pointer w-full bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white searchbtn submit-btn  rounded"
              >
                {t.search}
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
