import { Location } from "@prisma/client";
import { connect } from "http2";

export default class SearchHelper {
  static getAllRegions: () => Promise<Location[]> = async () => {
    return await fetch(`/api/locations`).then((res) => res.json());
  };

  static getLocationByKeyword: (keyword: string) => Promise<Location[]> =
    async (keyword) => {
      keyword = keyword.trim();
      keyword = keyword.replace(/\s+/g, " ");
      return await fetch(`/api/locations?keyword=${keyword}`).then((res) =>
        res.json()
      );
    };

  static getLocationByKeywordAndRegion: (
    regionId: number,
    keyword: string
  ) => Promise<Location[]> = async (regionId, keyword) => {
    keyword = keyword.trim();
    keyword = keyword.replace(/\s+/g, " ");
    return await fetch(
      `/api/locations?keyword=${keyword}&parentId=${regionId}`
    ).then((res) => res.json());
  };

  static setFlatLocationName(location: any) {
    let name = location.name;
    if (location.level_2_parent) {
      name += `, ${location.level_2_parent.name}`;
      SearchHelper.setFlatLocationName(location.level_1_parent);
    }
    if (location.level_1_parent) {
      name += `, ${location.level_1_parent.name}`;
    }

    return name;
  }

  static async getLocationByUuid(uuid: string) {
    return await fetch(`/api/locations?uuid=${uuid}`).then((res) => res.json());
  }
}
