export default class StatsHelper {
  static async registerListingView(entityId: number) {
    let action = "listing_view";

    let data = {
      action,
      entityId,
      sourceUrl: window.location.href,
    };

    this.saveAction(data);
  }

  static async registerProfileButtonsClick(
    listingId: number,
    sellerId: number,
    action: string
  ) {
    let data = {
      action,
      secondaryEntityId: listingId,
      entityId: sellerId,
      sourceUrl: window.location.href,
    };

    this.saveAction(data);
  }

  static async registerListingClick(entityId: number) {
    let action = "listing_click";
    let data = {
      action,
      entityId,
      sourceUrl: window.location.href,
    };

    this.saveAction(data);
  }

  static saveAction(actionData) {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.active.postMessage({
          type: "saveAction",
          data: actionData,
        });
      });
    }
  }
}
